import {Controller} from "stimulus"

const DEFAULT_REFRESH = 300 // 5 minutes

export default class extends Controller {
  static values = { 
    endpoint: String,
    refresh: Number,
    renderer: String
  }

  static targets = [ "results" ]

  connect() {
    if(this.endpointValue.trim().length==0) return
    this.fetchData(this.endpointValue)
    this.refresh()
  }

  draw(data) {
    this.resultsTarget.innerHTML = (data === null || data === undefined) ? "No data" : JSON.stringify(data)
  }
    
  fetchRenderer() {
    if(this.hasRendererValue) {
      return this.application.getControllerForElementAndIdentifier(this.element, this.rendererValue)
    }
    return this
  }
    
  fetchData(url) {
    return fetch(url)
      .then(response => {
        if(response.ok) return response.json()
        throw new Error("Server error response")
      })
      .then(data => {
        this.fetchRenderer().draw(data)
      })
      .catch(error => {
        return
      })
  }

  getRefreshTime() {
    return (this.hasRefreshValue) ? (this.refreshValue*1000) : (DEFAULT_REFRESH*1000)
  }

  refresh() {
    var refreshTimer = setInterval(() => {
      this.fetchData(this.endpointValue)
    },this.getRefreshTime())  
  }
}

import {Controller} from "stimulus"
import consumer from "../channels/consumer"
import {ajax} from '@rails/ujs'

export default class extends Controller {
  connect() {

    let printJobController = this;
    this.subscription = consumer.subscriptions.create({
        channel: "PrintJobChannel", print_job_id: this.data.get('printJobId')
      },
      {
        connected() {
          console.log("connected");
        },
        received(data) {
          console.log(data);
          printJobController.updateInfo();
        }
      }
    );
  }

  updateInfo() {
    ajax({
      url: `/print_jobs/${this.data.get('printJobId')}`,
      type: 'GET'
    })
  }

  disconnect() {
    this.subscription.unsubscribe();
  }
}
import { Controller } from 'stimulus';

export default class extends Controller {
  static targets = ["menu", "button"];
  handleClickOutside(event) {
    let menuClicked = this.menuTarget.contains(event.target);
    let buttonClicked = this.buttonTarget.contains(event.target);
    if(!menuClicked && !buttonClicked) {
      this.menuTarget.classList.add("hidden")
    }
  }

  connect() {
    document.addEventListener('click', this.handleClickOutside.bind(this))
  }

  disconnect() {
    document.removeEventListener('click', this.handleClickOutside.bind(this))
  }

  toggleMenu() {
    this.menuTarget.classList.toggle("hidden")
  }

}
// Visit The Stimulus Handbook for more details 
// https://stimulusjs.org/handbook/introduction
// 
// This example controller works with specially annotated HTML like:
//
// <div data-controller="hello">
//   <h1 data-target="hello.output"></h1>
// </div>

import {Controller} from "stimulus"

export default class extends Controller {
  static targets = ["printerSelect", "palletSelect", "createPrintJobButton", "palletFilterSelect"];

  connect() {
    this.disableSubmission();
    this.updatePalletOptions();
  }

  updatePalletOptions() {
    this.createPrintJobButtonTarget.disabled = true;
    fetch(`/printers/${this.printerSelectTarget.value}/pallets?filter=${this.palletFilterSelectTarget.value}`)
      .then((response) => response.json())
      .then((data) => {
        this.palletSelectTarget.options.length = 0;
        data.forEach((item, index) => {
          this.palletSelectTarget.options[index] = new Option(item[0], item[1])
        })
      }).finally(() => this.disableSubmission())
  }

  disableSubmission() {
    this.createPrintJobButtonTarget.disabled = this.palletSelectTarget.options.length === 0;
  }



}

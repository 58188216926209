import {Controller} from "stimulus"

export default class extends Controller {
  static targets = [ "results" ]

  connect() {
    this.draw()
  }

  disconnect() {
    this.draw()
  }

  draw(data) {
    this.resultsTarget.innerHTML = (data === null || data === undefined) ? "No data" : JSON.stringify(data)
  }
}

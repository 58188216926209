import {Controller} from "stimulus"

export default class extends Controller {
  connect() {
    this.load();
    // this.startRefreshing()

  }
  disconnect() {
    this.stopRefreshing()
  }

  startRefreshing() {
    this.refreshTimer = setInterval(() => {
      this.load()
    }, 10000)
  }

  stopRefreshing() {
    if (this.refreshTimer) {
      clearInterval(this.refreshTimer)
    }
  }

  load() {
    fetch(this.data.get("url"))
      .then(response => response.text())
      .then(html => {
        this.element.innerHTML = html;
        if(this.element.querySelectorAll('.notification').length > 0) {
          document.getElementById("notification-presence-indicator").classList.remove("hidden")
        }else {
          document.getElementById("notification-presence-indicator").classList.add("hidden")
        }
      })
  }
}
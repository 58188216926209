import { Controller } from 'stimulus'

export default class extends Controller {
  read() {
    fetch(this.data.get('mark-as-read-url'), {method: "POST"})
      .then(response => response.text())
      .then(_data => {
        this.element.remove();
      })
      .catch((error) => {
        console.error('Error:', error);
      });

  }
}
import {Controller} from "stimulus"

export default class extends Controller {
  static targets = [ "results" ]

  connect() {
    this.draw()
  }

  disconnect() {
    this.draw()
  }

  buildColumn(column) {
    let td = document.createElement("td")
    td.classList.add("px-1", "py-2", "whitespace-nowrap")
    td.innerHTML = column
    return td
  }

  buildRow(key,record) {
    let tr = document.createElement("tr")
    tr.append(this.buildColumn(key))
    if(!Array.isArray(record)) record = [record]
    record.forEach(column => {
      tr.append(this.buildColumn(column))
    })
    return tr
  }
  
  draw(data) {
    this.resultsTarget.innerHTML = null
    if(data === null || data === undefined) {
      return this.resultsTarget.innerHTML = "No Data"
    }
    Object.keys(data).forEach(k => {
      if(data.hasOwnProperty(k)) {
        this.resultsTarget.append(this.buildRow(k,data[k]))
      }
    })
  }
}
import {Controller} from 'stimulus'
import Pusher from 'pusher-js/with-encryption'

export default class extends Controller {

  initialize() {
    this.pusher = new Pusher('23602617f84a34c9ea77', {
      cluster: 'mt1'
    });
  }

  connect() {
    this.hidden = document.getElementsByClassName("loading")[0];
    this.hidden.classList.remove("hidden");
    this.channel = this.pusher.subscribe(`print-job-${this.data.get('printJobId')}`);
    this.channel.bind('stream', this.prependMessage.bind(this))
  }

  disconnect() {
    this.channel.unbind()
  }

  prependMessage(data) {
    this.hidden.classList.add('hidden');
    let p = document.createElement("p");
    p.classList.add('event-stream-item', 'border-b', 'border-gray-200', 'pb-2', 'mb-2');
    document.getElementById('event-stream').prepend(data, p);
  }
}